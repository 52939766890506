<template>
  <div class="new-user-consents">
    <sticker-with-text-component
      :title1="$t('new-user-consents.title1')"
      :title2="$t('new-user-consents.title2')"
    >
      <template #sticker>
        <img :src="require('images/sdk-second-life.png')">
      </template>
      <template #text>
        <p class="new-user-consents__text">
          {{ $t('new-user-consents.description') }}
        </p>
        <div class="new-user-consents__checkbox">
          <input
            id="consent"
            v-model="marketingValue"
            type="checkbox"
          >
          <label for="consent">
            {{ $t('marketing-consent.checkbox') }}
          </label>
        </div>
        <base-button
          :is-type="[
            'install',
            'simple'
          ]"
          @onClick="eventClick"
        >
          {{ $t('new-user-consents.button') }}
        </base-button>
      </template>
    </sticker-with-text-component>
  </div>
</template>

<script>
import { mParticleMetric } from '@/metrics';

export default {
    components: {
        StickerWithTextComponent: () =>
            import(
                /* webpackChunkName: "StickerWithTextComponent" */ '@/components/StickerWithTextComponent/StickerWithTextComponent'
            ),
        BaseButton: () =>
            import(/* webpackChunkName: "BaseButton"*/ '@RepoComponent/BaseButton/BaseButton'),
    },
    data() {
        return {
            marketingValue: false,
            dataCollectionValue: false,
        };
    },
    mounted() {
        mParticleMetric.track(mParticleMetric.EVENTS.SIGN_IN_CONSENT_VIEW);
    },
    methods: {
        eventClick() {
            this.$emit('onClick', {
                marketing: this.marketingValue,
            });
        },
    },
};
</script>

<style src="./NewUserConsentsComponent.scss" lang="scss" />